import { Badge } from 'antd';
import { Alert } from '../interfaces/Alert.ts';

interface Props {
  alerts: Alert[] | undefined;
  onClick: () => void;
}

export function NotificationBadge(props: Props) {
  return (
    <Badge count={props.alerts?.length} className={'ariane'} onClick={props.onClick}>
      <img src={'/theme/media/warning.svg'} width="30" alt="notification icon" />
    </Badge>
  );
}
