import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { Form, FormCheck, Modal } from 'react-bootstrap';
import { OverlayTooltip } from '../../../ui/Tooltip.tsx';
import { t } from 'i18next';
import { LoadingButton } from '../../../ui/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { patchVehicle } from '../../../queries/getVehicles.ts';
import { toast } from 'react-toastify';

interface Props {
  territorySlug: string;
  vehicle: IVehicle;
  showAutomataFeatures: boolean;
  setShowAutomataFeatures: (state: boolean) => void;
}

interface MutationPayload {
  territorySlug: string;
  vehicleId: number;
  content: { config: { can_arrive_by_itself: boolean } };
}

export function AutomataFeatures(props: Props) {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<IVehicle>(props.vehicle);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: MutationPayload) =>
      patchVehicle(payload.territorySlug, payload.vehicleId, payload.content),
    onSuccess: () => {
      toast.success(`${t('automata-features.patch-success')}`);
      setVehicle({
        ...vehicle,
        config: { ...vehicle.config, can_arrive_by_itself: !vehicle.config.can_arrive_by_itself },
      });
      setIsButtonLoading(false);
      props.setShowAutomataFeatures(false);
      queryClient.invalidateQueries({ queryKey: ['vehicles', props.territorySlug] });
    },
    onError: () => {
      toast.error(`${t('automata-features.patch-error')}`);
    },
    onSettled: () => {
      setIsButtonLoading(false);
    },
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function onSubmit(event) {
    event.preventDefault();
    setIsButtonLoading(true);
    mutation.mutate({
      territorySlug: props.territorySlug,
      vehicleId: vehicle.id,
      content: { config: { can_arrive_by_itself: event.target.elements.canArriveByItself.checked } },
    });
  }

  return (
    <Modal
      show={props.showAutomataFeatures}
      onHide={() => props.setShowAutomataFeatures(false)}
      size={'lg'}
      centered>
      <Modal.Header closeButton className={'border-0 mb-0 pb-0'}>
        <Modal.Title>
          <p className={'card-title'}>
            {t('automata-features.title')}
            <OverlayTooltip text={t('automata-features.title-tooltip')}>
              <img
                src={'/theme/media/info.svg'}
                width="25"
                height="25"
                className={'ms-1'}
                alt="Info icon"
              />
            </OverlayTooltip>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'mt-0 pt-0'}>
        <Form className={'m-0 p-0'} onSubmit={onSubmit}>
          <div>
            <p className={'card-subtitle'}>
              {t('vehicle.vehicle')} {vehicle.display_identifier}
            </p>
          </div>
          <div className={'row mt-5'}>
            <div className={'col-6 border-end'}>
              <p className={'card-subtitle text-center'}>{t('automata-features.level-3-features')}</p>
              <div className={'d-flex flex-row ms-3 mt-4'}>
                <Form.Check id="autonomousArrival">
                  <Form.Check.Input
                    type={'checkbox'}
                    id={'canArriveByItself'}
                    defaultChecked={vehicle.config.can_arrive_by_itself}
                  />
                  <FormCheck.Label>
                    <p className={'card-text'}>{t('automata-features.can-arrive-by-itself')}</p>
                  </FormCheck.Label>
                </Form.Check>
                <OverlayTooltip text={t('automata-features.level-3-can-arrive-by-itself')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="20"
                    height="20"
                    className={'ms-1'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </div>
              <div className={'d-flex flex-row ms-3 mt-2'}>
                <Form.Check>
                  <Form.Check.Input type={'checkbox'} checked={false} disabled={true} />
                  <FormCheck.Label>
                    <p className={'card-text'}>{t('automata-features.can-leave-by-itself')}</p>
                  </FormCheck.Label>
                </Form.Check>
                <OverlayTooltip text={t('automata-features.level-3-can-leave-by-itself')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="20"
                    height="20"
                    className={'ms-1'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </div>
            </div>
            <div className={'col-6 border-start'}>
              <p className={'card-subtitle text-center'}>{t('automata-features.level-4-features')}</p>
              <div className={'d-flex flex-row ms-3 mt-4'}>
                <Form.Check id="autonomousArrival">
                  <Form.Check.Input type={'checkbox'} checked={true} disabled={true} />
                  <FormCheck.Label>
                    <p className={'card-text'}>{t('automata-features.can-arrive-by-itself')}</p>
                  </FormCheck.Label>
                </Form.Check>
                <OverlayTooltip text={t('automata-features.level-4-can-arrive-by-itself')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="20"
                    height="20"
                    className={'ms-1'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </div>
              <div className={'d-flex flex-row ms-3 mt-2'}>
                <Form.Check id="autonomousArrival">
                  <Form.Check.Input type={'checkbox'} checked={true} disabled={true} />
                  <FormCheck.Label>
                    <p className={'card-text'}>{t('automata-features.can-leave-by-itself')}</p>
                  </FormCheck.Label>
                </Form.Check>
                <OverlayTooltip text={t('automata-features.level-4-can-leave-by-itself')}>
                  <img
                    src={'/theme/media/info.svg'}
                    width="20"
                    height="20"
                    className={'ms-1'}
                    alt="Info icon"
                  />
                </OverlayTooltip>
              </div>
            </div>
          </div>
          <div className={'row mt-5 d-flex justify-content-center'}>
            <div className={'col-6 text-center'}>
              <LoadingButton
                type={'submit-button'}
                text={t('automata-features.button-text')}
                imagePath={null}
                loading={isButtonLoading}
                onClick={undefined}
              />
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
