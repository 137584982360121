import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { t } from 'i18next';
import { Drawer } from 'antd';
import { ButtonGroup } from 'react-bootstrap';
import { LoadingButton } from '../../../ui/Button.tsx';
import { useState } from 'react';
import { LOG_TYPE_AUTOMATA, LOG_TYPE_VEHICLE } from '../../../constants.ts';
import { AutomataLogs } from './AutomataLogs.tsx';
import { VehicleSystemLogs } from './VehicleSystemLogs.tsx';

interface Props {
  territorySlug: string;
  vehicle: IVehicle;
  territoryConfig: TerritoryConfig | null | undefined;
  showLogs: boolean;
  setShowLogs: (bool: boolean) => void;
}

export function Log(props: Props) {
  const [selectedLogType, setSelectedLogType] = useState<string>(LOG_TYPE_AUTOMATA);

  return (
    <Drawer
      size={'large'}
      styles={{
        header: { border: 'none' },
      }}
      placement={'bottom'}
      open={props.showLogs}
      closeIcon={false}
      onClose={() => props.setShowLogs(false)}
      className={'rounded-top-4'}
      title={
        <>
          <div className={'mt-2 row'}>
            <p className={'card-title'}>
              <img src={'/theme/media/log-black.svg'} width="40" height="40" alt="Log icon" />
              <span className={'ms-2'}>{t('logs.title')}</span>
            </p>
          </div>
          <div className={'row'}>
            <div className={'col my-auto'}>
              <p className={'card-subtitle ms-5'}>
                {t('vehicle.vehicle')} {props.vehicle.display_identifier}
              </p>
            </div>
            <div className={'col text-end me-2'}>
              <ButtonGroup>
                <LoadingButton
                  type={
                    selectedLogType === LOG_TYPE_AUTOMATA ? 'submit-button' : 'submit-button-reverse'
                  }
                  text={t('logs.automata-button')}
                  imagePath={null}
                  loading={false}
                  onClick={() => setSelectedLogType(LOG_TYPE_AUTOMATA)}
                />
                <LoadingButton
                  type={selectedLogType === LOG_TYPE_VEHICLE ? 'submit-button' : 'submit-button-reverse'}
                  text={t('logs.vehicle-button')}
                  imagePath={null}
                  loading={false}
                  onClick={() => setSelectedLogType(LOG_TYPE_VEHICLE)}
                />
              </ButtonGroup>
            </div>
          </div>
        </>
      }>
      <div className={'ms-2 me-2'}>
        {selectedLogType === LOG_TYPE_AUTOMATA ? (
          <AutomataLogs territorySlug={props.territorySlug} vehicle={props.vehicle} />
        ) : (
          <VehicleSystemLogs
            territorySlug={props.territorySlug}
            territoryConfig={props.territoryConfig}
            vehicle={props.vehicle}
          />
        )}
      </div>
    </Drawer>
  );
}
