import { Alert } from '../../../interfaces/Alert.ts';
import { Drawer } from 'antd';
import { Key } from 'react';
import { AlertCard } from './AlertCard.tsx';
import { t } from 'i18next';

interface Props {
  territorySlug: string;
  alerts: Alert[] | undefined;
  showAlertDrawer: boolean;
  setShowAlertDrawer: (bool: boolean) => void;
}

export function AlertDrawer(props: Props) {
  return (
    <Drawer
      open={props.showAlertDrawer}
      placement={'right'}
      onClose={() => props.setShowAlertDrawer(false)}
      closeIcon={false}
      styles={{
        header: { border: 'none' },
        wrapper: {
          boxShadow: 'none',
          marginRight: '12px',
        },
        content: {
          borderRadius: '20px',
          marginTop: '72px',
          backgroundColor: 'rgb(115,119,145, 0.6)',
          height: 'calc(100vh - 60px - 50px - 24px)',
        },
        mask: { opacity: 0 },
      }}>
      {props.alerts?.length || 0 > 0 ? (
        <>
          {props.alerts?.map((alert: Alert, i: Key) => (
            <AlertCard territorySlug={props.territorySlug} alert={alert} key={i} />
          ))}
        </>
      ) : (
        <>
          <p className={'card-text mt-5 text-center'}>
            <img
              src={'/theme/media/success-check.svg'}
              width="30"
              alt="Success icon"
              className={'me-2'}
            />
            {t('alert.no-alert')}
          </p>
        </>
      )}
    </Drawer>
  );
}
