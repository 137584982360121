import { useQuery } from '@tanstack/react-query';
import { getAlert } from '../queries/getAlert.ts';

export const useAlert = (territorySlug: string) => {
  return useQuery({
    queryKey: ['alert', territorySlug],
    queryFn: () => getAlert(territorySlug),
    keepPreviousData: true,
    enabled: territorySlug !== undefined && territorySlug !== '',
    retry: false,
    refetchInterval: 10000,
  });
};
