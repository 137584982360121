import { Table } from 'antd';
import { FormatTime, getLogLevel } from '../../../utils.ts';
import { AutomataLog } from '../../../interfaces/ILog.ts';
import { Key } from 'react';
import { useAutomataLogs } from '../../../services/Logs.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { t } from 'i18next';

interface Props {
  territorySlug: string;
  vehicle: IVehicle;
}

export function AutomataLogs(props: Props) {
  const { data: AutomataLogsData, isLoading } = useAutomataLogs(props.territorySlug, props.vehicle.id);

  function getCheckValueDisplay(checkValue: string) {
    if (checkValue === 'true') {
      return <img src={'/theme/media/success-check.svg'} width="15" height="15" alt="Success icon" />;
    } else if (checkValue === 'false') {
      return <img src={'/theme/media/failed-check.svg'} width="15" height="15" alt="Failed icon" />;
    } else {
      return checkValue;
    }
  }

  const AutomataTableColumns = [
    {
      title: t('logs.table-time'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => <p className={'text-basic'}>{FormatTime(text, true)}</p>,
      width: '10%',
    },
    {
      title: t('logs.table-level'),
      dataIndex: 'level',
      key: 'level',
      render: (level: number) => (
        <span className={'mission-lifecycle rounded-4'} style={getLogLevel(level)}>
          {getLogLevel(level).name}
        </span>
      ),
      width: '10%',
      filters: [
        {
          text: 'Debug',
          value: 10,
        },
        {
          text: 'Info',
          value: 20,
        },
        {
          text: 'Warning',
          value: 30,
        },
        {
          text: 'Error',
          value: 40,
        },
        {
          text: 'Critical',
          value: 50,
        },
      ],
      onFilter: (value: number, record: AutomataLog) => record.level === value,
    },
    {
      title: t('logs.table-extra'),
      dataIndex: 'extra',
      key: 'extra',
      width: '30%',
      render: (extra: { [key: string]: string }) => (
        <ul className={'m-0 p-0 ms-2'}>
          {Object.keys(extra).map((key: string, index: Key) => (
            <li className={'m-0 p-0'} key={index}>
              <p className={'text-basic'}>
                {key} : {getCheckValueDisplay(JSON.stringify(extra[key]))}
              </p>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: t('logs.table-log'),
      dataIndex: 'message',
      key: 'message',
      width: '50%',
      render: (message: string) => <p className={'text-basic'}>{message}</p>,
    },
  ];

  return (
    <>
      <Table
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        columns={AutomataTableColumns}
        rowKey={'id'}
        pagination={false}
        loading={isLoading}
        scroll={{ y: 'max-content' }}
        dataSource={AutomataLogsData?.data}
      />
    </>
  );
}
