import { Table } from 'antd';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { FormatTime, getLogLevel } from '../../../utils.ts';
import { useVehicleSystemLogs } from '../../../services/Logs.ts';
import { VehicleLog } from '../../../interfaces/ILog.ts';
import { Key } from 'react';
import { t } from 'i18next';

interface Props {
  territorySlug: string;
  territoryConfig: TerritoryConfig | null | undefined;
  vehicle: IVehicle;
}
export function VehicleSystemLogs(props: Props) {
  const { data: VehicleSystemLogsData, isLoading } = useVehicleSystemLogs(
    props.territorySlug,
    props.vehicle.id,
    props.territoryConfig,
  );

  const VehicleSystemTableColumns = [
    {
      title: t('logs.table-time'),
      dataIndex: 'start_datetime',
      key: 'start_datetime',
      render: (text: string) => <p className={'text-basic'}>{FormatTime(text, true)}</p>,
      width: '10%',
    },
    {
      title: t('logs.table-level'),
      dataIndex: 'level',
      key: 'level',
      render: (level: string) => (
        <span className={'mission-lifecycle rounded-4'} style={getLogLevel(level)}>
          {getLogLevel(level).name}
        </span>
      ),
      width: '10%',
      filters: [
        {
          text: 'Debug',
          value: 'debug',
        },
        {
          text: 'Info',
          value: 'info',
        },
        {
          text: 'Warning',
          value: 'warning',
        },
        {
          text: 'Error',
          value: 'error',
        },
        {
          text: 'Critical',
          value: 'critical',
        },
      ],
      onFilter: (value: string, record: VehicleLog) => record.level === value,
    },
    {
      title: t('logs.table-code'),
      dataIndex: 'code',
      key: 'code',
      width: '10%',
      render: (code: string) => <p className={'text-basic'}>{code}</p>,
    },
    {
      title: t('logs.table-category'),
      dataIndex: 'category',
      key: 'category',
      width: '10%',
      render: (category: string) => <p className={'text-basic'}>{category}</p>,
    },
    {
      title: t('logs.table-context'),
      dataIndex: 'context',
      key: 'context',
      width: '60%',
      render: (context: { [key: string]: string }) => (
        <ul className={'m-0 p-0 ms-2'}>
          {Object.keys(context).map((key: string, index: Key) => (
            <li className={'m-0 p-0'} key={index}>
              <p className={'text-basic'}>
                {key} : {JSON.stringify(context[key])}
              </p>
            </li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <Table
      rowKey={'id'}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      columns={VehicleSystemTableColumns}
      pagination={false}
      loading={isLoading}
      scroll={{ y: 'max-content' }}
      dataSource={VehicleSystemLogsData?.data}
    />
  );
}
