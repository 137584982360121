import { t } from 'i18next';
import { FormatTime, getAlertWording } from '../../../utils.ts';
import { RoundedButton } from '../../../ui/Button.tsx';
import { Alert } from '../../../interfaces/Alert.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchAlert } from '../../../queries/getAlert.ts';
import { useState } from 'react';
import { ALERT_LIFECYCLE_RESOLVED } from '../../../constants.ts';
import { toast } from 'react-toastify';
import { OverlayTooltip } from '../../../ui/Tooltip.tsx';

interface Props {
  territorySlug: string;
  alert: Alert;
}

export function AlertCard(props: Props) {
  const queryClient = useQueryClient();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: (payload: { lifecycle: string }) =>
      patchAlert(props.territorySlug, props.alert.id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['alert', props.territorySlug] });
      toast.success(`${t('alert.alert-resolve-success')}`);
    },
    onError: () => {
      toast.error(`${t('alert.alert-resolve-fail')}`);
    },
    onSettled: () => {
      setIsButtonLoading(false);
    },
  });

  function closeAlert() {
    setIsButtonLoading(true);
    mutation.mutate({ lifecycle: ALERT_LIFECYCLE_RESOLVED });
  }

  return (
    <div className={'row rounded-4 mb-2 bg-white basic-shadow py-2'}>
      <div className={'col-2 my-auto text-center'}>
        <img src={'/theme/media/red-warning.svg'} width="30" alt="alert icon" />
      </div>
      <div className={'col-8 text-start p-0'}>
        <p className={'text-basic'}>{props.alert.get_content_object_display_name}</p>
        <p className={'card-text my-auto'}>
          {getAlertWording(props.alert.code)}
          <OverlayTooltip text={JSON.stringify(props.alert.extra)}>
            <img
              src={'/theme/media/info.svg'}
              width="15"
              height="15"
              className={'ms-1'}
              alt="Info icon"
            />
          </OverlayTooltip>
        </p>
        <p className={'text-basic'}>
          {FormatTime(props.alert.created_at, false)} | {props.alert.events} {t('alert.events')}
        </p>
        <p></p>
      </div>
      <div className={'col-2 my-auto text-center'}>
        <RoundedButton
          type={'rounded-button'}
          text={'Ok'}
          imagePath={null}
          loading={isButtonLoading}
          onClick={closeAlert}
        />
      </div>
    </div>
  );
}
