import { Accordion, Button } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMissions } from '../../../queries/getMissions.ts';
import { MissionCard } from './MissionCard.tsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebsocketToken } from '../Shift/Shift.tsx';

import { websocketUrl } from '../../../queries/constants.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { IMission } from '../../../interfaces/IMission.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { processMissionMessage } from '../../../websocket/processing.ts';
import { MissionMessage } from '../../../interfaces/Message.ts';

interface Props {
  websocketToken: WebsocketToken | undefined;
  territorySlug: string;
  territoryConfig: TerritoryConfig | undefined | null;
}

export function Mission(props: Props) {
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [missionList, setMissionList] = useState([]);

  const { data: missionsData } = useQuery({
    queryKey: ['missions', props.territorySlug],
    queryFn: () => getMissions(props.territorySlug, { page: String(1) }),
    enabled: selectedVehicle?.id === undefined,
    refetchInterval: 10000,
  });

  const { data: missionsVehicleData } = useQuery({
    queryKey: ['missions', props.territorySlug, selectedVehicle?.id],
    queryFn: () =>
      selectedVehicle?.display_identifier
        ? getMissions(props.territorySlug, {
            vehicle_display_identifier: selectedVehicle?.display_identifier,
          })
        : null,
    enabled: selectedVehicle?.id !== undefined,
    refetchInterval: 10000,
  });

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket<MissionMessage>(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (missionsData !== undefined) {
      if (selectedVehicle?.id === undefined) {
        setMissionList(missionsData?.data['results']);
      } else {
        setMissionList(missionsVehicleData?.data['results']);
      }
    }
  }, [missionsData, missionsData?.data, missionsVehicleData?.data, selectedVehicle?.id]);

  useEffect(() => {
    if (
      props.territoryConfig?.mission.websocket_enabled &&
      readyState === ReadyState.OPEN &&
      props.websocketToken &&
      missionList !== undefined &&
      missionList.length > 0
    ) {
      sendJsonMessage({
        type: 'init',
        auth_token: props.websocketToken.WEBSOCKET_TOKEN,
        object_type: 'mission',
        object_id_list: missionList.map((mission: IMission) => mission.provider_identifier),
      });
    }
  }, [readyState, props.websocketToken, missionList, props.territoryConfig]);

  useEffect(() => {
    if (lastJsonMessage?.message_type === 'mission' && missionList !== undefined) {
      processMissionMessage(lastJsonMessage, missionList);
      setMissionList(missionList);
    } else {
      setMissionList(missionsData?.data['results']);
    }
  }, [lastJsonMessage]);

  const redirectMissionHistory = () => {
    navigate(`/mission-history/${props.territorySlug}/`);
  };

  return (
    <div className={'mission-container'}>
      <div className={'row m-0 p-0 pt-3 pb-3'}>
        <div>
          <p className={'card-title ms-2'}>
            <img src={'/theme/media/mission-icon.svg'} width="56" height="42" alt="Mission icon" />
            <span className={'ms-3'}>{t('mission.title')}</span>
          </p>
        </div>
      </div>
      <Accordion className={'mission-scroll-container'}>
        <div className={'accordion accordion-flush text-center'}>
          {missionList?.map((mission: IMission, i: number) => (
            <MissionCard
              key={i}
              mission={mission}
              territorySlug={props.territorySlug}
              territoryConfig={props.territoryConfig}
              index={i}
            />
          ))}
        </div>
      </Accordion>
      <div className={'text-center mt-3'}>
        <Button className={'green-button'} type="submit" onClick={() => redirectMissionHistory()}>
          <p>
            <img src={'/theme/media/green-clock.svg'} width="25" height="25" alt="Clock icon" />
            <span className={'ms-1 my-auto'}>{t('mission.history-link')}</span>
          </p>
        </Button>
      </div>
    </div>
  );
}
