import { Node } from './components/Dashboard/Map/Map.tsx';
import { TerritoryConfig } from './interfaces/TerritoryConfig.ts';
import { LatLngTuple } from 'leaflet';
import {
  MISSION_LIFECYCLE_ABORTED,
  MISSION_LIFECYCLE_ARRIVAL,
  MISSION_LIFECYCLE_ARRIVED,
  MISSION_LIFECYCLE_DEPARTURE,
  MISSION_LIFECYCLE_ON_THE_WAY,
  MISSION_LIFECYCLE_OUT_OF_ITINERARY,
  MISSION_LIFECYCLE_PENDING,
  MISSION_LIFECYCLE_REJECTED,
  MISSION_LIFECYCLE_START,
  MISSION_LIFECYCLE_SUCCESS,
  SHIFT_STATUS_CANCEL,
  SHIFT_STATUS_FINISHED,
  SHIFT_STATUS_ONGOING,
  SHIFT_STATUS_PAUSED,
  SHIFT_STATUS_VALIDATE,
} from './constants.ts';
import { getConfiguration } from './queries/getConfiguration.ts';
import Cookies from 'js-cookie';
import { t } from 'i18next';

export function GetMapCenterCoordinated(nodes: Node[]): LatLngTuple {
  const lat: number[] = [];
  const lon: number[] = [];
  nodes.forEach(function (item) {
    lat.push(item['position']['lat']);
    lon.push(item['position']['lon']);
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [lat.reduce((x, y) => x + y) / lat.length, lon.reduce((x, y) => x + y) / lon.length];
}

export function FormatDate(rawDate: string): string {
  const rawDateSec = new Date(rawDate);
  return new Date(rawDateSec).toLocaleString();
}

export function FormatTime(rawDate: string, second: boolean): string {
  const rawDateSec = new Date(rawDate);
  const format: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  if (second) {
    format['second'] = '2-digit';
  }
  return new Date(rawDateSec).toLocaleTimeString([], format);
}

export async function getTerritoryConfiguration(territorySlug: string): Promise<TerritoryConfig> {
  const configuration = await getConfiguration(territorySlug);
  if (configuration?.data !== undefined) {
    return configuration.data;
  } else {
    throw 'Configuration has not been retrieved';
  }
}

export function getNodeNameById(nodes: Node[], node_id: number) {
  if (nodes !== undefined) {
    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index].padam_id === node_id) {
        return nodes[index].padam_name;
      }
    }
  }
}

interface Colors {
  color: string;
  backgroundColor: string;
}
export function getLifecycleColors(lifecycle: string): Colors {
  let colors: Colors;
  switch (lifecycle) {
    case MISSION_LIFECYCLE_PENDING:
      colors = { color: '#717587', backgroundColor: '#E8E8EC' };
      break;
    case MISSION_LIFECYCLE_START:
      colors = { color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case MISSION_LIFECYCLE_DEPARTURE:
      colors = { color: '#A45CFF', backgroundColor: '#E3CDFF' };
      break;
    case MISSION_LIFECYCLE_ARRIVAL:
      colors = { color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case MISSION_LIFECYCLE_ARRIVED:
      colors = { color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case MISSION_LIFECYCLE_ON_THE_WAY:
      colors = { color: '#A45CFF', backgroundColor: '#E3CDFF' };
      break;
    case MISSION_LIFECYCLE_SUCCESS:
      colors = { color: '#198155', backgroundColor: '#ECFCE5' };
      break;
    case MISSION_LIFECYCLE_REJECTED:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    case MISSION_LIFECYCLE_ABORTED:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    case MISSION_LIFECYCLE_OUT_OF_ITINERARY:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    default:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
  }
  return colors;
}

export function getShiftStatusColors(status: string): Colors {
  let colors: Colors;
  switch (status) {
    case SHIFT_STATUS_VALIDATE:
      colors = { color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case SHIFT_STATUS_ONGOING:
      colors = { color: '#198155', backgroundColor: '#ECFCE5' };
      break;
    case SHIFT_STATUS_CANCEL:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    case SHIFT_STATUS_PAUSED:
      colors = { color: '#717587', backgroundColor: '#E8E8EC' };
      break;
    case SHIFT_STATUS_FINISHED:
      colors = { color: '#A45CFF', backgroundColor: '#E3CDFF' };
      break;
    default:
      colors = { color: '#D3180C', backgroundColor: '#FFE5E5' };
  }
  return colors;
}

interface LogLevel {
  name: string;
  color: string;
  backgroundColor: string;
}

export function getLogLevel(logLevel: number | string): LogLevel {
  let output: LogLevel;
  switch (logLevel) {
    case 10:
      output = { name: 'Debug', color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case 'debug':
      output = { name: 'Debug', color: '#0078FF', backgroundColor: '#C5E7FE' };
      break;
    case 20:
      output = { name: 'Info', color: '#A45CFF', backgroundColor: '#E3CDFF' };
      break;
    case 'info':
      output = { name: 'Info', color: '#A45CFF', backgroundColor: '#E3CDFF' };
      break;
    case 30:
      output = {
        name: 'Warning',
        color: '#FCBC06',
        backgroundColor: '#FFDE77',
      };
      break;
    case 'warning':
      output = {
        name: 'Warning',
        color: '#FCBC06',
        backgroundColor: '#FFDE77',
      };
      break;
    case 40:
      output = { name: 'Error', color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    case 'error':
      output = { name: 'Error', color: '#D3180C', backgroundColor: '#FFE5E5' };
      break;
    case 50:
      output = {
        name: 'Critical',
        color: '#D3180C',
        backgroundColor: '#FFE5E5',
      };
      break;
    case 'critical':
      output = {
        name: 'Critical',
        color: '#D3180C',
        backgroundColor: '#FFE5E5',
      };
      break;
    default:
      output = { name: 'Notset', color: '#0078FF', backgroundColor: '#C5E7FE' };
  }
  return output;
}

export function getFlagName(language: string): string {
  let flagName: string;
  flagName = 'en';
  if (language.startsWith('en')) {
    flagName = 'en';
  } else if (language.startsWith('fr')) {
    flagName = 'fr';
  }
  return flagName;
}
export function getToken(): string | undefined {
  return Cookies.get('token');
}
export function setToken(token: string): void {
  const secure: boolean = import.meta.env.VITE_ENV !== 'LOCAL';
  Cookies.set('token', token, { expires: 1, secure: secure, sameSite: 'strict' });
}

export function removeToken(): void {
  Cookies.remove('token');
}

export function getSessionExpiration(): string | null {
  return sessionStorage.getItem('expiration');
}
export function setSessionExpiration(hours: number): void {
  const now = new Date();
  const expiration = now.setTime(now.getTime() + hours * 1000 * 60 * 60);
  sessionStorage.setItem('expiration', expiration.toString());
}
export function sessionIsExpired(): boolean {
  let sessionIsExpired: boolean = true;
  const expiration: string | null = getSessionExpiration();
  if (expiration !== null) {
    const now = new Date();
    const expirationDate = new Date(Number(expiration));
    sessionIsExpired = now > expirationDate;
  }
  return sessionIsExpired;
}

export function getAlertWording(code: number): string {
  return t(`alert.${code}`);
}
