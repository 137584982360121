import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  doorStatusColor,
  formatUpdatedAt,
  getBatteryColor,
  getBatteryType,
  getMissionCreationPayload,
} from './utils.ts';
import { patchVehicle, postCreateMission } from '../../../queries/getVehicles.ts';
import { Node } from '../Map/Map.tsx';
import { getNodeNameById, getShiftStatusColors } from '../../../utils.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FormatTime } from '../../../utils.ts';
import {
  AUTOMATA_LEVEL_3,
  AUTOMATA_LEVEL_4,
  AUTOMATA_OFF,
  PLACE_ACTIVITIES,
} from '../../../constants.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { useShiftByVehicle } from '../../../services/Vehicle.ts';
import { Territory } from '../../../interfaces/Territory.ts';
import { AutomataFeatures } from './AutomataFeatures.tsx';
import { LoadingButton } from '../../../ui/Button.tsx';
import { Log } from '../Logs/Log.tsx';
import { VehicleDisplayIdentifier } from './VehicleDisplayIdentifier.tsx';

interface Props {
  vehicle: IVehicle;
  index: number;
  territorySlug: string;
  geography: Node[];
  territoryConfig: TerritoryConfig | undefined | null;
  territoryObj: Territory | undefined;
}

export function Vehicle(props: Props) {
  const { t } = useTranslation();
  const { setSelectedVehicle } = useContext(SelectedVehicleContext);
  const [isCreatingMission, setIsCreatingMission] = useState(false);
  const [vehicle, setVehicle] = useState(props.vehicle);
  const [showCreateMission, setShowCreateMission] = useState(false);
  const [showAutomataFeatures, setShowAutomataFeatures] = useState(false);
  const [showDisplayIdentifierModal, setShowDisplayIdentifierModal] = useState(false);
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [territoryConfig, setTerritoryConfig] = useState<TerritoryConfig | undefined | null>(
    props.territoryConfig,
  );

  const shift = useShiftByVehicle(props.territorySlug, vehicle?.id || 0);

  useEffect(() => {
    setTerritoryConfig(props.territoryConfig);
  }, [props.territoryConfig]);

  useEffect(() => {
    setVehicle(props.vehicle);
  }, [props.vehicle]);

  const onEnter = () => {
    setSelectedVehicle(vehicle);
  };
  const onExit = () => {
    setSelectedVehicle(undefined);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmitMissionCreation(event) {
    setIsCreatingMission(true);
    event.preventDefault();
    try {
      await postCreateMission(
        props.territorySlug,
        vehicle.id,
        getMissionCreationPayload(event, props.territoryConfig),
      );
      toast.success(`${t('vehicle.create-mission-toaster-success')} ${vehicle.display_identifier}`);
    } catch (e) {
      toast.error(`${t('vehicle.create-mission-toaster-error')} ${vehicle.display_identifier}`);
    } finally {
      setIsCreatingMission(false);
      setShowCreateMission(false);
    }
  }
  const handleAutomataState = (new_state: string) => {
    const data = {
      automata_state: new_state,
    };
    try {
      patchVehicle(props.territorySlug, vehicle.id, data);
      setVehicle({ ...vehicle, automata_state: new_state });
    } catch (e) {
      toast.error(
        `${t('vehicle.vehicle')} ${vehicle.display_identifier} ${t('vehicle.automata-update-error')}`,
      );
    }
  };

  return (
    <Accordion.Item eventKey={String(props.index)} className={'basic-shadow rounded-3 mt-2'}>
      <Accordion.Header className={'rounded'}>
        <Row className={'w-100'}>
          <Col lg={12} xl={6} className={'my-auto text-start'}>
            <Row>
              <Col lg={4}>
                {vehicle.is_online ? (
                  <img
                    src={`/theme/media/shuttle-connected.svg`}
                    height="48"
                    className="rounded-2"
                    alt="connected shuttle"
                  />
                ) : (
                  <img
                    src={`/theme/media/shuttle-disconnected.svg`}
                    height="48"
                    className="rounded-2"
                    alt="disconnected shuttle"
                  />
                )}
              </Col>
              <Col lg={8} className={'my-auto'}>
                <p className={'card-text m-0'}>{vehicle.display_identifier}</p>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={6} className={'my-auto ps-3 border-start'}>
            <p className={'text-bold shift-color'}>
              <img src={'/theme/media/shift.svg'} width="20" height="20" alt="Shift icon" />
              <span className={'ms-1'}>
                {shift?.data?.data[0]?.id ? `${shift?.data?.data[0]?.id}` : `${t('vehicle.no-shift')}`}
              </span>
            </p>
            {shift?.data?.data[0]?.id ? (
              <>
                <p className={'mt-1'}>
                  <img src={'/theme/media/clock.svg'} width="20" height="20" alt="Clock icon" />
                  <span className={'ms-1 text-muted'}>
                    {FormatTime(shift?.data?.data[0]?.start_datetime, false)} -{' '}
                    {FormatTime(shift?.data?.data[0]?.end_datetime, false)}
                  </span>
                </p>
                <p className={'text-basic mt-3 pb-1'}>
                  <span
                    className={'mission-lifecycle rounded-4'}
                    style={getShiftStatusColors(shift?.data?.data[0]?.status)}>
                    {shift?.data?.data[0]?.status}
                  </span>
                </p>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body onEntered={onEnter} onExit={onExit} className={'mb-3'}>
        <Container>
          <Form>
            <Row>
              <Col lg={12} xl={5} className={'my-auto'}>
                <p className={'text-bold text-start'}>
                  {vehicle.display_identifier}
                  <a className={'ariane ms-1'} onClick={() => setShowDisplayIdentifierModal(true)}>
                    <img
                      src={'/theme/media/pencil-black.svg'}
                      width="17"
                      height="17"
                      alt="Vehicle name"
                    />
                  </a>
                </p>
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col lg={12} xl={5} className={'my-auto'}>
                <p className={'text-basic text-start'}>{t('vehicle.automata-enabled')}</p>
              </Col>
              <VehicleDisplayIdentifier
                territorySlug={props.territorySlug}
                vehicle={vehicle}
                showDisplayIdentifierModal={showDisplayIdentifierModal}
                setShowDisplayIdentifierModal={setShowDisplayIdentifierModal}
              />
              <Col lg={12} xl={7} className={'my-auto text-start text-xl-end'}>
                <ButtonGroup className={'shadow-sm rounded-start-4 rounded-end-4'}>
                  <Button
                    className={'rounded-start-4 automata-button'}
                    onClick={() => handleAutomataState(AUTOMATA_OFF)}
                    active={vehicle.automata_state === AUTOMATA_OFF}>
                    off
                  </Button>
                  <Button
                    className={'automata-button'}
                    onClick={() => handleAutomataState(AUTOMATA_LEVEL_3)}
                    active={vehicle.automata_state === AUTOMATA_LEVEL_3}>
                    3
                  </Button>
                  <Button
                    className={'rounded-end-4 automata-button'}
                    onClick={() => handleAutomataState(AUTOMATA_LEVEL_4)}
                    active={vehicle.automata_state === AUTOMATA_LEVEL_4}>
                    4
                  </Button>
                </ButtonGroup>
                <a className={'ariane'} onClick={() => setShowAutomataFeatures(true)}>
                  <img
                    src={'/theme/media/settings-logo.svg'}
                    width="25"
                    height="25"
                    className={'ms-2'}
                    alt="Automata settings"
                  />
                </a>
                <AutomataFeatures
                  territorySlug={props.territorySlug}
                  vehicle={vehicle}
                  showAutomataFeatures={showAutomataFeatures}
                  setShowAutomataFeatures={setShowAutomataFeatures}
                />
              </Col>
            </Row>
          </Form>
          <Row className={'mt-2'}>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.last-message-received')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {formatUpdatedAt(vehicle.updated_at)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.shuttle-provider')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>{vehicle.provider.toLowerCase()}</p>
            </Col>
          </Row>
          {territoryConfig?.vehicle.charge_percentage_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-basic text-start'}>{t('vehicle.battery-level')}</p>
              </Col>
              <Col xs={12} xl={7}>
                <p className={'text-basic text-start text-xl-end'}>
                  <i
                    className={
                      'text-basic bi bi-battery' +
                      getBatteryType(Number(vehicle.charge_percentage)) +
                      ' ' +
                      'text' +
                      getBatteryColor(Number(vehicle.charge_percentage))
                    }>
                    {vehicle.charge_percentage}%
                  </i>
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {territoryConfig?.vehicle.doors_status_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-basic text-start'}>{t('vehicle.door-status')}</p>
              </Col>
              <Col lg={12} xl={7}>
                <p
                  className={
                    'text-basic text-start text-xl-end text-' + doorStatusColor(vehicle.door_status)
                  }>
                  {vehicle.door_status.toLowerCase()}
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.robot-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>{vehicle.robot_mode.toLowerCase()}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.supervision-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {vehicle.supervision_mode.toLowerCase()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.latitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {vehicle.latitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.longitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {vehicle.longitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.current-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(props.geography, vehicle.current_node) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-basic text-start'}>{t('vehicle.next-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(props.geography, vehicle.next_node) || '-'}
              </p>
            </Col>
          </Row>
          <hr className={'text-muted'} />
          <Row className={'g-2 text-start'}>
            {props.territoryObj?.permissions.can_create_missions ? (
              <Col>
                <Button onClick={() => setShowCreateMission(true)} className={'submit-button'}>
                  <p>
                    <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Plus icon" />
                    <span className={'ms-1 my-auto'}>{t('vehicle.create-mission-button')}</span>
                  </p>
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col>
              <LoadingButton
                type={'submit-button'}
                loading={false}
                imagePath={'/theme/media/log.svg'}
                text={t('logs.button')}
                onClick={() => setShowLogs(true)}
              />
              <Log
                territorySlug={props.territorySlug}
                vehicle={vehicle}
                territoryConfig={props.territoryConfig}
                showLogs={showLogs}
                setShowLogs={setShowLogs}
              />
            </Col>
          </Row>
          <div className={'create-mission-modal'}>
            <Modal show={showCreateMission} onHide={() => setShowCreateMission(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  <p className={'card-subtitle'}>Vehicle - {vehicle.display_identifier}</p>
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={onSubmitMissionCreation}>
                <Modal.Body className={'justify-content-between'}>
                  <Form.Group as={Row} className={'d-flex align-items-center'}>
                    <Col sm={3}>
                      <Form.Label>{t('vehicle.create-mission-destination')}</Form.Label>
                    </Col>
                    <Col sm={9}>
                      <Form.Select id={'nodesSelect'} className={'rounded-5'}>
                        {props.geography?.map((node, i) => (
                          <option value={Number(node?.padam_id)} key={i}>
                            {node?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  {territoryConfig?.mission.activity_enabled &&
                  territoryConfig?.mission.waypoints_enabled ? (
                    <>
                      <Form.Group as={Row} className={'d-flex align-items-center mt-3'}>
                        <Col sm={3}>
                          <Form.Label>{t('vehicle.activity')}</Form.Label>
                        </Col>
                        <Col sm={9}>
                          <Form.Select id={'activitiesSelect'} className={'rounded-5'}>
                            {PLACE_ACTIVITIES.map(activity => (
                              <option value={activity.number} key={activity.number}>
                                {activity.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}
                </Modal.Body>
                <Modal.Footer className={'border-0'}>
                  <Button
                    type={'submit'}
                    className={'submit-button m-auto mb-3 mt-2'}
                    disabled={isCreatingMission}>
                    {isCreatingMission ? (
                      <span className={'d-flex justify-content-center'}>
                        <Spinner size={'sm'} animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <span className={'ms-2'}>{t('vehicle.create-mission-submit')}</span>
                      </span>
                    ) : (
                      <p>
                        <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Clock icon" />
                        <span className={'ms-1'}>{t('vehicle.create-mission-submit')}</span>
                      </p>
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
